<template>
  <div class="admin-windows-installs">
    <div class="page-header">
      <h1 class="page-title">
        <a-button
          type="default"
          style="display: inline; margin-right: 15px"
          @click.prevent="$router.push('/admin/settings')"
          icon="left"
        ></a-button>

        <admin-org-indicator></admin-org-indicator>

        Admin - Settings - Windows Installs
      </h1>
      <div class="actions">
        <a-button
          @click.prevent="loadUpdaterInstances"
          :disabled="isLoading"
          class="button-margin-left btn-rounded"
          icon="reload"
          size="large"
          type="default"
          >Refresh</a-button
        >
      </div>
    </div>

    <p>
      Deploy and track your teamSOS installations on Windows using our MSI
      updater service to see details here
    </p>

    <!-- Loading -->
    <div v-if="isLoading" class="loader">
      <a-spin></a-spin>
    </div>
    <!-- / Loading -->

    <!-- Loaded -->
    <div v-if="!isLoading">
      <!-- No results -->
      <a-alert
        v-if="!isLoading && updaterInstances.length == 0"
        type="info"
        message="No windows installs to show"
      >
      </a-alert>
      <!-- / No results -->

      <!-- Results -->
      <!-- {{ updaterInstances }} -->

      <a-table
        v-if="updaterInstances.length"
        :columns="columns"
        :data-source="updaterInstancesForTable"
        :pagination="false"
        bordered
      >
      </a-table>
      <!-- / Results -->
    </div>
    <!-- / Loaded- -->
  </div>
</template>

<script>
import moment from "moment";
const _ = require("lodash");
import { mapGetters } from "vuex";
import updaterInstances from "../../../api/updater-instances";

const COLUMNS = [
  {
    title: "Computer",
    dataIndex: "machineName",
  },
  {
    title: "Last Seen",
    dataIndex: "lastSeenHuman",
  },
  {
    title: "teamSOS",
    dataIndex: "teamSosVersion",
  },
  {
    title: "Updater",
    dataIndex: "updaterVersion",
  },
  {
    title: "Release",
    dataIndex: "release",
  },
];

export default {
  data() {
    return {
      columns: COLUMNS,
      isLoading: false,
      updaterInstances: [],
      // updaterInstances: [
      //   {
      //     created: "2022-06-27T08:24:10.5990987Z",
      //     lastModified: "2022-06-27T08:24:22.6889306Z",
      //     lastSeen: "2022-07-15T12:51:28.6739339Z",
      //     components: [
      //       {
      //         lastChanged: "2022-06-27T08:24:22.6889306Z",
      //         componentType: 1,
      //         identifier: "Self",
      //         version: "1.0.0.1",
      //       },
      //       {
      //         lastChanged: "2022-06-27T08:24:22.6889306Z",
      //         componentType: 2,
      //         identifier: "LMTP://CN = 18CEA45E-7A04-444B-BFB8-9FA39191A8A2",
      //         version: "0AC08173BCD68ED6A3B7543755D6B336E2349884",
      //       },
      //       {
      //         lastChanged: "2022-06-27T08:24:22.6889306Z",
      //         componentType: 3,
      //         identifier: "teamSOS.teamSOS",
      //         version: "1.0.138.0",
      //       },
      //     ],
      //     machineName: "TW-PRT-852",
      //     osVersion: "Microsoft Windows NT 10.0.19044.0",
      //     domainName: "WOODEATON",
      //     ipAddress: "83.151.202.114",
      //     id: "4e0d1b12-7926-48ae-bd1b-67629759d246",
      //     ownerId: "f95fc687-4816-4569-969d-da42fccc64b3",
      //   },
      // ],
    };
  },
  computed: {
    ...mapGetters("admin", {
      selectedOrganisation: "selectedOrganisation",
      tenantId: "tenantId",
    }),

    updaterInstancesForTable() {
      if (this.updaterInstances && this.updaterInstances.length) {
        return _.map(this.updaterInstances, (updaterInstance) => {
          let ui = { ...updaterInstance };

          // Timestamps
          ui.createdHuman = ui.created
            ? moment.utc(ui.created).format("dddd Do MMMM Y, HH:mm")
            : "-";
          ui.lastModifiedHuman = ui.lastModified
            ? moment.utc(ui.lastModified).format("dddd Do MMMM Y, HH:mm")
            : "-";
          ui.lastSeenHuman = ui.lastSeen
            ? moment.utc(ui.lastSeen).format("dddd Do MMMM Y, HH:mm")
            : "-";

          let selfComponent = null;
          // let certificateComponent = null;
          let msixBundle = null;
          if (ui.components) {
            selfComponent = _.find(ui.components, { componentType: 1 });
            // certificateComponent = _.find(ui.components, { componentType: 2 });
            msixBundle = _.find(ui.components, { componentType: 3 });
          }

          ui.updaterVersion =
            selfComponent && selfComponent.version
              ? selfComponent.version
              : "-";
          ui.teamSosVersion =
            msixBundle && msixBundle.version ? msixBundle.version : "-";

          ui.release = updaterInstance.release ? updaterInstance.release : 'default'

          return ui;
        });
      }
      return [];
    },
  },
  created() {
    if (!this.selectedOrganisation) {
      this.$message.info("Please select an organisation");
      this.$router.push("/admin");
    }
    // this.loadUpdaterInstances();
  },
  methods: {
    loadUpdaterInstances() {
      let vm = this;
      vm.isLoading = true;
      vm.updaterInstances = [];
      updaterInstances
        .getUpdaterInstancesForOrg(vm.tenantId, vm.selectedOrganisation.id)
        .then((r) => {
          vm.isLoading = false;
          vm.updaterInstances = r.data;
        })
        .catch((e) => {
          vm.isLoading = false;
          vm.$message.error("Error loading windows installs");
          console.log(e);
        });
    },
  },
};
</script>

<style lang="scss">
.admin-windows-installs {
  .loader {
    background: #fff;
    padding: 50px;
    text-align: center;
  }

  .ant-table {
    background: #fff !important;
  }

  padding-bottom: 50px;
}
</style>